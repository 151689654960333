import { Box, Grid, Stack, Typography } from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import PendingIcon from "@mui/icons-material/Pending";

export default function View({ events, setIsModalOpen }) {
  return (
    <Stack p={2} spacing={2}>
      <Typography variant="h6">Lista de eventos</Typography>
      <Stack spacing={2}>
        {events
          .sort((a, b) => {
            // Convertir las fechas a objetos Date
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);

            // Ordenar por fecha, más reciente primero
            return dateB - dateA;
          })
          .map((aevent, index) => (
            <Box
              onClick={() => setIsModalOpen({ mode: "view", event: aevent })}
              key={index}
              sx={{
                bgcolor: "#bdbdbd85",
                py: 1.3,
                px: 2.2,
                borderRadius: 2,
                transition: "all ease 0.3s",
                cursor: "pointer",
                ":hover": {
                  bgcolor: "#87878785",
                },
              }}
            >
              <Grid
                container
                sx={{
                  svg: {
                    color: "#808080",
                  },
                  "& .MuiStack-root": {
                    maxWidth: "99%",
                  },
                }}
              >
                <Grid item lg={4} xl={3}>
                  <Stack spacing={0.6}>
                    <Stack direction="row" gap="3px" alignItems="center">
                      <CalendarMonthIcon />
                      <Typography noWrap>Fecha: {aevent.date}</Typography>
                    </Stack>
                    <Stack direction="row" gap="3px" alignItems="center">
                      <AccessTimeIcon />
                      <Typography noWrap>Hora: {aevent.time}:30</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item lg={4} xl={3}>
                  <Stack spacing={0.6}>
                    <Stack direction="row" gap="3px" alignItems="center">
                      <MeetingRoomIcon />
                      <Typography noWrap>
                        Número de sala: {aevent.room}
                      </Typography>
                    </Stack>

                    <Stack direction="row" gap="3px" alignItems="center">
                      <PendingIcon />
                      <Typography noWrap>
                        Hay pendiente: {aevent.isPending ? "Si" : "No"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item lg={4} xl={6}>
                  <Stack spacing={0.6}>
                    <Stack direction="row" gap="3px" alignItems="center">
                      <AbcIcon />
                      <Typography noWrap>
                        Nombre del evento: {aevent.name}
                      </Typography>
                    </Stack>

                    <Stack direction="row" gap="3px" alignItems="center">
                      <PersonIcon />
                      <Typography noWrap>
                        Nombre del cliente: {aevent.nameClient}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          ))}
      </Stack>
    </Stack>
  );
}
