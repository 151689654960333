export default function formatPrice(precio) {
    if (!precio) return 0;
    var pricef = parseInt(precio, 10);

    pricef = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
    }).format(pricef);

    pricef = pricef.replace("$", "").trim();

    return "$" + pricef;
}

