export default function getPreview(FileA) {
    if (isFile(FileA)) {
        const previewURL = URL.createObjectURL(FileA);
        return previewURL;
    } else {
        return null;
    }
}

function isFile(file) {
    return file instanceof File;
}