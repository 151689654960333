import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { format, isSameDay, parseISO } from "date-fns";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { parse } from "date-fns";

const Dia = ({ currentDate, events, setIsModalOpen, setCurrentDate }) => {
  const horasFijas = Array.from({ length: 15 }, (_, i) => 9 + i);

  function handleOpenModal(hour, mode, aevent) {
    if (mode === "new") {
      setIsModalOpen({
        selectedHour: hour,
        selectedDate: currentDate,
        selectedRoom: 1,
      });
    } else {
      setIsModalOpen({ mode: "view", event: aevent });
    }
  }

  return (
    <>
      <Grid container>
        <Grid item lg={3} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{ ".Mui-selected": { color: "#fff!important" } }}
              value={dayjs(currentDate)}
              onChange={(newValue) => {
                setCurrentDate(
                  parseDate(newValue["$y"], newValue["$M"] + 1, newValue["$D"])
                );
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={9} xl={10}>
          <Typography px={2} pt={2} variant="h6">
            {format(currentDate, "dd MMMM yyyy")}
          </Typography>
          <Stack>
            {horasFijas.map((horaFija, index) => {
              const eventsForHour = events.filter((event) => {
                const eventDate = parseISO(event.date);
                const eventTime = event.time;

                // revisa si el evento es el mismo día
                const isSameEventDay = isSameDay(eventDate, currentDate);
                const isSameEventTime = eventTime === horaFija;

                return isSameEventDay && isSameEventTime;
              });
              return (
                <React.Fragment key={index}>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{
                      p: 2,
                      py: 2.4,
                      ":hover": { bgcolor: "#bdbdbd" },
                      cursor: "pointer",
                      transition: "all ease 0.3s",
                    }}
                    onClick={() => handleOpenModal(horaFija, "new")}
                  >
                    <Typography variant="subtitle2">{horaFija}:30</Typography>
                    {eventsForHour.length > 0 && (
                      <Stack direction="row" spacing={1.5}>
                        {eventsForHour.map((event, eventIndex) => (
                          <Button
                            key={eventIndex}
                            sx={{
                              py: 0.8,
                              borderRadius: 10,
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenModal(horaFija, "view", event);
                            }}
                          >
                            <Typography color="white" variant="body2">
                              {event.name}
                            </Typography>
                          </Button>
                        ))}
                      </Stack>
                    )}
                  </Stack>

                  {index !== horasFijas.length - 1 && (
                    <Divider orientation="horizontal" flexItem />
                  )}
                </React.Fragment>
              );
            })}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Dia;

function parseDate(year, month, day) {
  // Crear una cadena de fecha a partir de los valores enteros
  const dateString = `${year}-${String(month).padStart(2, "0")}-${String(
    day
  ).padStart(2, "0")}`;

  // Formato de la cadena de fecha
  const formatString = "yyyy-MM-dd";

  // Convertir la cadena de fecha en un objeto de fecha
  const parsedDate = parse(dateString, formatString, new Date());

  return parsedDate;
}
