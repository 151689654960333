import GroupsIcon from '@mui/icons-material/Groups';
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LogoutIcon from '@mui/icons-material/Logout';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const globalOptions = [

];

const headerOptions = {
    menu: [
        {
            title: "Centros",
            icon: HolidayVillageIcon,
            route: "/centros",
            roles: ["superadmin"]
        },
        {
            title: "Usuarios",
            icon: GroupsIcon,
            route: "/usuarios",
            roles: ["superadmin"]
        },
        {
            title: "Etiquetas",
            icon: LocalOfferIcon,
            route: "/etiquetas",
            roles: ["superadmin", "admin"]
        },
    ],
    header: [
        {
            title: "Calendario",
            route: "/calendario",
            icon: CalendarMonthIcon
        },
        {
            title: "Cerrar Sesión",
            route: "/logout",
            icon: LogoutIcon
        },
    ],
    default: [
        ...globalOptions,
    ]
}

export default headerOptions;