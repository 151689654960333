import { Button, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import headerOptions from "../utils/headerOptions";

export default function MenuView({ children, noOverflow }) {

  const { userAuth } = useAuth();
  const MenuItem = styled(Button)(({ theme }) => ({
    justifyContent: "left",
    backgroundColor: "#a7a7a757",
    boxShadow: "none",
    paddingTop: "0.86rem",
    paddingBottom: "0.86rem",
    gap: 10,
    color: "#161616",
    ":hover": {
      backgroundColor: "#a7a7a7b5",
      boxShadow: "none"
    },
  }));




  return (
    <Stack>
      <Grid container p={{ xs: 2, xl: 2, lg: 1 }} spacing={{ lg: 2, xl: 3 }}>
        <Grid item display={{ xs: "none", lg: "initial" }} lg={2.3} sx={{ position: "relative" }}>
          <Paper variant="rounded" sx={{ position: "sticky", borderRadius: "10px", height: { lg: "calc(100vh - 80px - 1rem - 10px)", xl: "calc(100vh - 80px - 24px - 75px)" }, p: { lg: "5px 1rem", xl: 4 } }}>
            <Stack spacing={{ lg: 0.9, xl: 2 }} sx={{ overflowY: "auto", svg: { color: "#535353f0!important", width: 24, height: 24 }, "::-webkit-scrollbar": { display: "none" } }} maxHeight="100%">
              <Stack spacing={1} display={{ lg: "none", xl: "initial" }}>
                <Typography color="#000000a3">Navegación</Typography>
                <Divider />
              </Stack>
              {headerOptions.menu.map((aitem, i) => {
                if (!!aitem?.roles) {
                  if (!aitem.roles.includes(userAuth?.role)) return undefined;
                }
                return <MenuItem key={i} LinkComponent={Link} to={aitem.route}><aitem.icon />{aitem.title}</MenuItem>;
              })}
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={9.7} >
          <Paper variant="rounded" sx={{ overflow: !noOverflow ? "auto" : "none", borderRadius: "10px", height: { lg: "calc(100vh - 80px - 1rem - 33px)", xl: "calc(100vh - 80px - 24px - 58px)" }, p: { xs: "1rem", xl: 3 } }}>
            {children}
          </Paper>
        </Grid>
      </Grid>
    </Stack>
  );
}
