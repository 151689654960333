import { Backdrop, Box } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

function ImageBox({ src, Delete }) {
    const [openBD, setOpenBD] = useState(false);
    return <>
        <Box sx={{
            boxShadow: "1px 1px 6px -3px #898989",
            cursor: "pointer",
            position: "relative",
            height: "100px",
            width: "100px",
            userSelect: "none",
            img: {
                userSelect: "none",
                height: "100px",
                width: "100px",
                transition: "all 0.2s",
                ":hover": {
                    filter: "brightness(0.5)"
                },
                objectFit: "100% 100%"
            },

            ".badge": {
                display: !!Delete ? "initial" : "none",
                bgcolor: "#d90000",
                position: "absolute",
                userSelect: "none",
                top: -10,
                right: -10,
                zIndex: 1000,
                borderRadius: "50%",
                fontWeight: 600,
                color: "#fff",
                px: 1,
                py: 0.5,
                transition: "all 0.2s",
                ":hover": {
                    filter: "brightness(0.8)"
                },
            }
        }}>
            <div className="badge" onClick={Delete}>X</div>
            <img src={src} onClick={() => setOpenBD(true)}></img>
        </Box>
        <Backdrop
            open={openBD}
            sx={{ bgcolor: "rgb(0 0 0 / 85%)" }}
            onClick={() => setOpenBD(false)}
        >
            <Box sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                img: {
                    maxWidth: {
                        xs: "90%",
                        lg: "70%"
                    },
                },
                svg: {
                    position: "absolute",
                    right: "1%",
                    top: "2%",
                    color: "#0000009c",
                    cursor: "pointer",
                    borderRadius: "50%",
                    boxShadow: "0px 0px 11px 1px rgb(255 255 255 / 69%)",
                    p: 0.8,
                    bgcolor: "#fff",
                }
            }}>
                <CloseIcon />
                <img src={src} onClick={(e) => e.preventDefault()} />
            </Box>
        </Backdrop>
    </>;
}

export default ImageBox;