import AddIcon from "@mui/icons-material/Add";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import MenuView from "../../components/MenuView";
import SnackAlert from "../../components/SnackAlert";
import GlobalHeader from "../../components/header";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import Search from "../../components/Search";

export default function View() {
  const [rows, setRows] = useState([]);
  const [openBD, setOpenBD] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();
  const { loadApi } = useApi();
  const backendPath = "users";
  const frontPath = "usuarios";

  useEffect(() => {
    setOpenBD(true);
    loadApi(backendPath, true, "get")
      .then((response) => {
        setRows(response.data);
        setOpenBD(false);
      })
      .catch((e) => {
        showSnack(e.message, undefined, 0);
      });
  }, []);

  function Delete(id) {
    const confirmBox = window.confirm("¿Estás seguro de que quieres eliminar?");
    if (confirmBox) {
      setOpenBD(true);
      loadApi(`${backendPath}/${id}`, true, "delete")
        .then((response) => {
          setRows((adata) => adata.filter((aitem) => aitem.id !== id));
          setOpenBD(false);
          showSnack(response.data.message, "success");
        })
        .catch((e) => {
          setOpenBD(false);
          showSnack(e.message);
        });
    }
  }

  function View(id) {
    navigate(`/${frontPath}/view/${id}`);
  }

  function Edit(id) {
    navigate(`/${frontPath}/edit/${id}`);
  }

  return (
    <>
      <Stack>
        <GlobalHeader />
        <MenuView noOverflow>
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
              flexWrap="wrap"
              gap={2}
            >
              <Typography
                color="#000000a3"
                sx={{
                  fontSize: {
                    xs: "1.5rem",
                    md: "1rem",
                    textTransform: "uppercase",
                  },
                }}
              >
                Lista de {frontPath}
              </Typography>
              <Stack spacing={2} direction="row">
                <Button
                  LinkComponent={Link}
                  to={`/${frontPath}/add`}
                  sx={{ py: 0.5 }}
                  startIcon={<AddIcon />}
                >
                  AGREGAR
                </Button>
                <Search
                  onChange={({ target }) => setSearchTerm(target.value || "")}
                />
              </Stack>
            </Stack>
            <Divider sx={{ mt: 1 }} />
            <Box
              sx={{
                height: {
                  lg: "calc(100vh - 80px - 90px)",
                  xl: "calc(100vh - 80px - 110px)",
                },
                width: "100%",
                overflow: "auto",
              }}
            >
              <DataTable
                searchTerm={searchTerm}
                rows={rows}
                Delete={Delete}
                View={View}
                Edit={Edit}
                Columns={[
                  { name: "name", label: "Nombre", flex: 37 },
                  { name: "username", label: "Correo electrónico", flex: 37 },
                  { name: "role", label: "Rol", flex: 13 },
                ]}
              />
            </Box>
          </Stack>
        </MenuView>
      </Stack>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBD}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
