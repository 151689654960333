import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const useApi = () => {
  const [loadingApi, setLoading] = useState([]);
  const [loadedApi, setLoadedApi] = useState([]);
  const [errorApi, setErrorApi] = useState(false);

  const tokenDataApi = {};

  function setError(error) {
    setErrorApi(error);
  }

  const loadApi = async (endpoint, token, type, body, file = false) => {
    setErrorApi(false);
    setLoading((value) => [...value, `${type}__${endpoint}`]);
    setLoadedApi((prevState) =>
      prevState.filter((item) => item !== `${type}__${endpoint}`)
    );

    try {
      let headers = {};

      if (token) {
        const token2 = Cookies.get("CCorbitToken");
        if (!token2) {
          setError("No estas logueado. Inicia sesión.");
          console.error("Token no encontrado en localStorage");
        }
        headers["Authorization"] = `Bearer: ${token2}`;
        if (file) {
          headers["Content-Type"] = "multipart/form-data";
        }
      }

      const config = {
        method: type,
        url: `https://api-studio11.ccorbit.com/${endpoint}`,
        headers: headers,
      };

      if (body) {
        if (file) {
          const formData = new FormData();
          Object.entries(body).forEach(([key, value]) => {
            if (typeof value === "object" && value !== null) {
              try {
                if (isFile(value) || isFile(value?.[0])) {
                  if (Array.isArray(value)) {
                    for (let value2 of value) {
                      formData.append(key, value2);
                    }
                  } else {
                    formData.append(key, value);
                  }

                } else {
                  formData.append(key, JSON.stringify(value));
                }
              } catch (e) {
                formData.append(key, value);
              }
            } else {
              formData.append(key, value);
            }
          });
          config.data = formData;
        } else {
          config.data = body;
        }
      }

      const response = await axios(config);
      if (response.data.error) {
        setError(response.data.error);
        throw new Error(response.data.error);
      }
      setLoading((prevState) =>
        prevState.filter((item) => item !== `${type}__${endpoint}`)
      );
      setLoadedApi((value) => [...value, `${type}__${endpoint}`]);
      return response;
    } catch (error) {
      setLoading((prevState) =>
        prevState.filter((item) => item !== `${type}__${endpoint}`)
      );
      if (error.response) {
        if (error.response.data.message) {
          if (
            error.response.data.message === "INVALID_TOKEN" ||
            error.response.data.message === "MISSING_TOKEN"
          ) {
            window.location.replace("/logout");
          }
          setError(error.response.data.message);
          throw new Error(error.response.data.message);
        } else {
          setError(
            "Error interno del servidor, actualiza la página e intente nuevamente."
          );
          throw new Error(
            "Error interno del servidor, actualiza la página e intente nuevamente."
          );
        }
      } else {
        setError(
          "Error de conexión, actualiza la página e intente nuevamente."
        );
        throw new Error(
          "Error de conexión, actualiza la página e intente nuevamente."
        );
      }
    }
  };

  return { loadApi, loadingApi, errorApi, tokenDataApi, loadedApi };
};

function isFile(file) {
  return file instanceof File;
}

export default useApi;
