const mainFields = {
  centros: {
    backend: "campus",
    files: false,
    fields: [
      {
        name: "name",
        label: "Nombre del centro",
        type: "text",
      },
      {
        name: "address",
        label: "Dirección",
        type: "text",
      },
      {
        name: "rooms",
        label: "Numero de salas",
        type: "number",
      },
      {
        name: "status",
        label: "Estado del centro",
        type: "select_manual",
        select_fields: [{ label: "Activo", value: "Activo" }, { label: "Deshabilitado", value: "Deshabilitado" }]
      },
    ],
  },
  usuarios: {
    backend: "users",
    files: false,
    fields: [
      {
        name: "name",
        label: "Nombre completo",
        type: "text"
      },
      {
        name: "username",
        label: "Correo electrónico",
        type: "text"
      },
      {
        name: "password",
        label: "Contraseña",
        type: "password",
        placeholder: "(Si no desea cambiar la contraseña dejar en blanco)",
        placeholderview: ["edit"]
      },
      {
        name: "password2",
        label: "Confirmar contraseña",
        type: "password",
        placeholder: "(Si no desea cambiar la contraseña dejar en blanco)",
        placeholderview: ["edit"]
      },
      {
        name: "role",
        label: "Tipo de usuario",
        type: "select_manual",
        select_fields: [{ label: "Administrador", value: "admin" }, { label: "SuperAdmin", value: "superadmin" }, { label: "Usuario", value: "user" }]
      },
    ]
  },
};

export default mainFields;
