import { Backdrop, Box, Button, CircularProgress, Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import GlobalHeader from "../../components/header";
import MenuView from "../../components/MenuView";
import { useEffect, useState } from "react";
import PickerButton from "./PickerButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OPmodal from "./OPmodal";
import useApi from "../../hooks/useApi";
import SnackAlert from "../../components/SnackAlert";
import useSnack from "../../hooks/useSnack";

export default function View() {
    const { loadApi } = useApi();
    const [OPmodalS, setOPmodalS] = useState(false);
    const [openBD, setOpenBD] = useState(false);
    const { msgSB, openSB, showSnack, typeSB } = useSnack();
    const [mainData, setMainData] = useState([]);


    useEffect(() => {
        setOpenBD(true);
        loadApi("tags", true, "get").then((response) => setMainData(response.data)).catch((e) => showSnack(e.message)).finally(() => setOpenBD(false));
    }, [])


    function addElement(field, name, color) {
        setOpenBD(true);
        loadApi("tags", true, "post", {
            field, name, color
        }).then((response) => {
            setMainData((adata) => {
                const newadata = [...adata];
                newadata.push(response.data.item);
                return newadata;
            });
            showSnack(response.data.message, "success");
            setOPmodalS(null)
        }).catch((e) => showSnack(e.message)).finally(() => setOpenBD(!true))


    }

    function editElement(name, color, id) {
        setOpenBD(true);
        loadApi(`tags/${id}`, true, "put", {
            color, name
        }).then((response) => {
            changeElement(id, "color", color);
            changeElement(id, "name", name);
            setOPmodalS(null);
            showSnack(response.data.message, "success");
        }).catch((e) => showSnack(e.message)).finally(() => setOpenBD(false));

        function changeElement(id, attribute, value) {
            setMainData((adata) => {
                const newadata = [...adata];
                newadata[adata.findIndex((aaitem) => aaitem.id === id)][attribute] = value;
                return newadata;
            });
        }
    }

    function deleteElement(id) {
        const confirmD = window.confirm("¿Deseas eliminar esta etiqueta?");
        if (!confirmD) return;
        setOpenBD(true);
        loadApi(`tags/${id}`, true, "delete").then((response) => {
            setMainData((adata) => {
                var newadata = [...adata];
                newadata = newadata.filter((aaitem) => aaitem.id !== id);
                return newadata;
            });
            showSnack(response.data.message, "success");
        }).catch((e) => showSnack(e.message)).finally(() => setOpenBD(false));

    }


    return <>
        <GlobalHeader />
        <MenuView>
            <Stack spacing={2}>
                <Typography variant="h5">Gestión de etiquetas (multiselect)</Typography>
                <Divider />
                {fields.map((aafield, fieldI) => {
                    const filtered = mainData.filter((aaitem) => aaitem.field === aafield.name);
                    return <Stack key={fieldI} spacing={1} sx={{
                        pb: fieldI === (fields.length - 1) ? 2 : 7,
                        borderBottom: fieldI === (fields.length - 1) ? undefined : "1px solid #a8a8a85e",
                        width: {
                            lg: "50%",
                            xl: "40%"
                        }
                    }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" pb={1}>
                            <Typography variant="h6">{aafield.label}:</Typography>
                            <Button onClick={() => setOPmodalS({ field: aafield.name, label: aafield.label })}> + Agregar</Button>
                        </Stack>
                        {filtered.length < 1 && <Typography color="GrayText" align="center">No hay etiquetas para mostrar.</Typography>}
                        {
                            filtered.map((element, i) =>
                                <Box key={i} sx={{
                                    transition: "all ease 0.3s",
                                    ":hover": {
                                        bgcolor: "#e6e6e6ba"
                                    },
                                    px: 2,
                                    py: 1,
                                    boxShadow: "0px 2px 9px -5px rgb(153 153 153)",
                                    borderRadius: 2,
                                    border: "1px solid #81818140",
                                    "*": {
                                        userSelect: "none"
                                    }
                                }}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Typography fontWeight={500}>{element.name}</Typography>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Box sx={{
                                                "*": {
                                                    fontFamily: "inherit!important"
                                                }
                                            }}>
                                                <PickerButton color={element.color} disabled ></PickerButton>
                                            </Box>
                                            <IconButton onClick={() => setOPmodalS({ label: aafield.label, mode: "edit", id: element.id, text: element.name, color: element.color })}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                            <IconButton onClick={() => deleteElement(element.id)}>
                                                <DeleteIcon color="error" />
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                </Box>
                            )}
                    </Stack>
                })}
            </Stack>
        </MenuView>
        <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
        <Backdrop
            sx={{ color: "#fff", zIndex: 9999 }}
            open={openBD}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <OPmodal open={OPmodalS} setOpen={setOPmodalS} save={(field, text, color, mode, id) => mode === "edit" ? editElement(text, color, id) : addElement(field, text, color)} />
    </>
}


const fields = [{
    label: "Tipo de evento",
    name: "eventType"
},
{
    label: "Profesor",
    name: "professor"
},
{
    label: "Estado",
    name: "status"
},
{
    label: "Servicios",
    name: "services"
},
]