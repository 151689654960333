import { useEffect } from "react";
import { useAuth } from "../../hooks/useAuth";

function Redirect() {
  const { logoutAuth } = useAuth();
  useEffect(() => {
    logoutAuth();
  }, []);

  return <></>;
}

export default Redirect;
