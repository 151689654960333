import AddIcon from "@mui/icons-material/Add";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfWeek,
  format,
  startOfWeek,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import useSnack from "../../hooks/useSnack";
import Logo from "../../images/logowhite.svg";
import Dia from "./dia";
import Mes from "./mes";
import EventModal from "./modal";
import Semana from "./semana";
import Year from "./year";
import Lista from "./lista";
import LogoutIcon from "@mui/icons-material/Logout";

function Calendar() {
  const [currentDate, setCurrentDate] = useState(
    toZonedTime(new Date(), "Europe/Madrid")
  );
  const [view, setView] = useState("Mes");
  const { userAuth } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const [openBD, setOpenBD] = useState(false);
  const [allCampus, setAllCampus] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [selectCampus, setSelectedCampus] = useState("");
  const [rooms, setRooms] = useState([]);
  const { loadApi } = useApi();

  //Carga inicial
  useEffect(() => {
    setOpenBD(true);
    loadApi("campus/all?id=" + selectCampus, true, "get")
      .then((response) => {
        const resCampus = response.data.campus;
        setAllTags(response.data.tags);
        setOpenBD(false);
        setAllCampus(resCampus);
        if (selectCampus === "") setSelectedCampus(resCampus[0]?.id || 0);
        setRooms(
          resCampus.find(
            (aitem) =>
              aitem.id ===
              (selectCampus === "" ? resCampus[0]?.id : selectCampus)
          )?.rooms || 0
        );

        setEvents(response.data.events);
      })
      .catch((e) => {
        showSnack(e.message, undefined, 0);
      });
  }, [selectCampus]);

  const handleOpenModal = () => setIsModalOpen(true);
  const changeView = (newView) => setView(newView);
  const goToToday = () => setCurrentDate(new Date());
  const goToNext = () => {
    switch (view) {
      case "Dia":
        setCurrentDate(addDays(currentDate, 1));
        break;
      case "Semana":
        setCurrentDate(addWeeks(currentDate, 1));
        break;
      case "Mes":
        setCurrentDate(addMonths(currentDate, 1));
        break;
      case "Año":
        setCurrentDate(addYears(currentDate, 1));
        break;
      default:
        break;
    }
  };

  const goToPrevious = () => {
    switch (view) {
      case "Dia":
        setCurrentDate(addDays(currentDate, -1));
        break;
      case "Semana":
        setCurrentDate(addWeeks(currentDate, -1));
        break;
      case "Mes":
        setCurrentDate(addMonths(currentDate, -1));
        break;
      case "Año":
        setCurrentDate(addYears(currentDate, -1));
        break;
      default:
        break;
    }
  };
  const getTitle = () => {
    switch (view) {
      case "Dia":
        return format(currentDate, "dd MMMM yyyy");
      case "Semana":
        return `${format(startOfWeek(currentDate), "dd MMMM")} - ${format(
          endOfWeek(currentDate),
          "dd MMMM yyyy"
        )}`;
      case "Mes":
        return format(currentDate, "MMMM yyyy");
      case "Año":
        return format(currentDate, "yyyy");
      default:
        return "";
    }
  };

  const renderCalendar = () => {
    switch (view) {
      case "Dia":
        return (
          <Dia
            currentDate={currentDate}
            events={events}
            setIsModalOpen={setIsModalOpen}
            setCurrentDate={setCurrentDate}
          />
        );
      case "Semana":
        return (
          <Semana
            currentDate={currentDate}
            events={events}
            setIsModalOpen={setIsModalOpen}
          />
        );
      case "Mes":
        return (
          <Mes
            salas={rooms}
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            currentDate={currentDate}
            events={events}
            tags={allTags}
          />
        );
      case "Año":
        return (
          <Year
            currentDate={currentDate}
            events={events}
            setIsModalOpen={setIsModalOpen}
          />
        );
      case "Lista":
        return <Lista events={events} setIsModalOpen={setIsModalOpen} />;
      default:
        return null;
    }
  };

  const [month, setMonth] = React.useState("Mes");

  const handleChange = (event) => {
    setMonth(event.target.value);
    changeView(event.target.value);
  };

  return (
    <>
      <Stack>
        <Stack
          p={{ lg: 2, xl: 2 }}
          bgcolor="#002B44"
          direction={{ xs: "column", sm: "row" }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Box
              component={Link}
              to="/"
              sx={{ pr: 1, borderRight: "2px solid white" }}
            >
              <img src={Logo} width={200} height="100%" />
            </Box>
            <Typography variant="h6" color="white" noWrap>
              {userAuth.name}
            </Typography>
          </Stack>
          <Stack
            flexWrap="wrap"
            direction="row"
            alignItems="center"
            display={view === "Lista" && "none"}
          >
            <Button
              variant="outlined"
              sx={{
                border: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "none",
                },
              }}
              onClick={goToPrevious}
            >
              <ArrowBackIosIcon sx={{ color: "#fff" }} />
            </Button>
            <Typography variant="h6" sx={{ color: "#fff" }} textAlign="center">
              {getTitle()}
            </Typography>
            <Button
              variant="outlined"
              sx={{
                border: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                  border: "none",
                },
              }}
              onClick={goToNext}
            >
              <ArrowForwardIosIcon sx={{ color: "#fff" }} />
            </Button>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            flexWrap="wrap"
          >
            <Box sx={{ minWidth: 130 }}>
              <FormControl fullWidth>
                <Select
                  placeholder="hola"
                  value={selectCampus}
                  onChange={({ target }) => setSelectedCampus(target.value)}
                  sx={{
                    color: "#fff",
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#fff",
                      },
                  }}
                >
                  {allCampus.map((aitem) => (
                    <MenuItem key={aitem.id} value={aitem.id}>
                      {aitem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Button
              variant="outlined"
              sx={{
                py: 1.8,
                color: "#fff",
                borderColor: "#fff",
                "&:hover": {
                  color: "#fff",
                  borderColor: "#fff",
                },
              }}
              onClick={goToToday}
            >
              Hoy
            </Button>
            <Button
              variant="outlined"
              sx={{
                py: 1.8,
                color: "#fff",
                borderColor: "#fff",
                "&:hover": {
                  color: "#fff",
                  borderColor: "#fff",
                },
              }}
              onClick={handleOpenModal}
            >
              <AddIcon /> Evento
            </Button>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <Select
                  value={month}
                  sx={{
                    color: "#fff",
                    "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        borderColor: "#fff",
                      },
                  }}
                  onChange={handleChange}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Semana"}>Semana</MenuItem>
                  <MenuItem value={"Mes"}>Mes</MenuItem>
                  <MenuItem value={"Año"}>Año</MenuItem>
                  <MenuItem value={"Lista"}>Lista</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {["admin", "superadmin"].includes(userAuth?.role) && (
              <IconButton LinkComponent={Link} to="/etiquetas">
                <SettingsIcon sx={{ color: "#fff" }} />
              </IconButton>
            )}
            <IconButton LinkComponent={Link} to="/logout">
              <LogoutIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack>{renderCalendar()}</Stack>
      </Stack>

      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <EventModal
        setEvents={setEvents}
        tags={allTags}
        rooms={rooms}
        open={isModalOpen}
        onClose={() => setIsModalOpen(null)}
        setOpenBD={setOpenBD}
        showSnack={showSnack}
        selectCampus={selectCampus}
        setAllTags={setAllTags}
      />
      <Backdrop sx={{ color: "#fff", zIndex: 10000 }} open={openBD}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default Calendar;
