import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import React, { useState } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import getBestColor from "../../utils/getBestColor";

const Mes = ({ currentDate, events, setIsModalOpen, salas, tags }) => {
  const diasmes = ["D", "L", "M", "X", "J", "V", "S"];
  const firstDayOfMonth = startOfMonth(currentDate);
  const lastDayOfMonth = endOfMonth(currentDate);
  const firstDayOfWeek = startOfWeek(firstDayOfMonth);
  const lastDayOfWeek = endOfWeek(lastDayOfMonth);
  const daysOfMonth = eachDayOfInterval({
    start: firstDayOfWeek,
    end: lastDayOfWeek,
  });
  const [treeHours, setTreeHours] = useState([
    [17, 18, 19, 20, 21],
    [17, 18, 19, 20, 21],
    [17, 18, 19, 20, 21],
    [17, 18, 19, 20, 21],
    [17, 18, 19, 20, 21],
    [17, 18, 19, 20, 21],
  ]);

  function handleOpenInfoModal(day, hour, room, mode, aevent) {
    if (mode === "new") {
      setIsModalOpen({
        selectedHour: hour,
        selectedDate: day,
        selectedRoom: room,
      });
    } else {
      setIsModalOpen({ mode: "view", event: aevent });
    }
  }

  function UpDownHour(mode, location) {
    if (mode === "up") {
      setTreeHours((adata) => {
        var newData = [...adata];
        const hour1 = adata[location][0] - 1;
        const hour2 = adata[location][1] - 1;
        const hour3 = adata[location][2] - 1;
        const hour4 = adata[location][3] - 1;
        const hour5 = adata[location][4] - 1;
        if (hour1 < 0) {
          return adata;
        } else {
          newData[location] = [hour1, hour2, hour3, hour4, hour5];
          return newData;
        }
      });
    } else {
      setTreeHours((adata) => {
        var newData = [...adata];
        const hour1 = adata[location][0] + 1;
        const hour2 = adata[location][1] + 1;
        const hour3 = adata[location][2] + 1;
        const hour4 = adata[location][3] + 1;
        const hour5 = adata[location][4] + 1;
        if (hour3 > 21) {
          return adata;
        } else {
          newData[location] = [hour1, hour2, hour3, hour4, hour5];
          return newData;
        }
      });
    }
  }

  return (
    <Stack direction="row" spacing={2} pt={2}>
      <Grid container spacing={0} height="calc(100vh - 110px)">
        <Grid item xs={0.6}></Grid>
        {diasmes.map((dia, i) => (
          <Grid
            item
            xs={1.6285}
            key={i}
            container
            justifyContent="center"
            sx={{
              py: 0.5,
              fontWeight: 500,
              border: "solid 1px",
              borderColor: "#70757a",
              bgcolor: "#70757a1f",
              borderBottom: 0,
            }}
          >
            <Typography
              sx={{ userSelect: "none" }}
              variant="subtitle2"
              color="GrayText"
              fontWeight={500}
            >
              {dia}
            </Typography>
          </Grid>
        ))}
        {daysOfMonth.map((day, j) => (
          <React.Fragment key={j}>
            {[0, 7, 14, 21, 28, 35].includes(j) && (
              <Grid item xs={0.6}>
                <Stack alignItems="center">
                  <Stack
                    direction="row"
                    spacing={0.5}
                    pt={1.5}
                    sx={{
                      borderTop: j === 0 ? "1px solid #9eb2b38a" : "none",
                    }}
                  >
                    <IconButton
                      onClick={() => UpDownHour("up", locateHour(j))}
                      sx={{
                        bgcolor: "#b0b0b05e",
                        py: "0px!important",
                        px: {
                          lg: "1px",
                          xl: "5px",
                        },
                        borderRadius: 1,
                      }}
                    >
                      <ArrowDropUpIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => UpDownHour("down", locateHour(j))}
                      sx={{
                        px: {
                          lg: "1px",
                          xl: "5px",
                        },
                        bgcolor: "#b0b0b05e",
                        py: "0px!important",
                        borderRadius: 1,
                      }}
                    >
                      <ArrowDropDownIcon />
                    </IconButton>
                  </Stack>
                  {treeHours[locateHour(j)].map((ahour, i2) => (
                    <Typography
                      sx={{ userSelect: "none" }}
                      key={i2}
                      mt={i2 === 0 ? 0.3 : 1.2}
                    >
                      {ahour}:30
                    </Typography>
                  ))}
                  <Box sx={{ width: "90%", mt: { lg: "11px", xl: 2.6 } }}>
                    <Divider />
                  </Box>
                </Stack>
              </Grid>
            )}
            <Grid
              item
              key={j}
              xs={1.6285}
              container
              sx={{
                border: "solid 1px",
                borderColor: "#70757a",
                transition: "all ease 0.3s",
                ":hover": {
                  bgcolor: "#cfcfcf",
                },
                borderTop: 0,
                minHeight: "19%",
              }}
            >
              <Stack
                alignItems="left"
                width="100%"
                height="100%"
                px={1}
                spacing={0.85}
              >
                <Typography
                  textAlign="center"
                  align="center"
                  variant="subtitle2"
                  fontWeight={400}
                  sx={{ pt: 1, userSelect: "none" }}
                >
                  {format(day, "d")}
                </Typography>
                {Array.from({ length: treeHours[0].length }).map(
                  (_, mainIndex) => (
                    <Stack
                      key={mainIndex}
                      direction="row"
                      spacing={1}
                      mt={0.5}
                      justifyContent="center"
                    >
                      {Array.from({ length: salas }).map((_, index) => {
                        const sala = index + 1;
                        const hasEvent = events.some(
                          (event) =>
                            isSameDay(day, parseISO(event.date)) &&
                            event.room === sala &&
                            event.time ===
                              treeHours?.[locateHour(j)]?.[mainIndex]
                        );

                        var aevent = {};
                        var acolor = "rgba(0,212,255,1)";

                        if (!!hasEvent) {
                          aevent = events.find(
                            (aevent2) =>
                              isSameDay(day, parseISO(aevent2.date)) &&
                              aevent2.room === sala &&
                              aevent2.time ===
                                treeHours?.[locateHour(j)]?.[mainIndex]
                          );
                          acolor = tags.find(
                            (aaitem) => aaitem.id === aevent?.eventType?.[0]
                          )?.color;
                        }

                        return (
                          <Box
                            key={index}
                            sx={{
                              backgroundColor: "#fff",
                              background: !!hasEvent ? acolor : undefined,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              px: 1.2,
                              py: 0.3,
                              borderRadius: 2,
                              border: "1px solid #63636370",
                              boxShadow:
                                !!hasEvent && !!aevent?.isPending
                                  ? `0 0 0 1px white, 0 0 0 2px ${acolor}`
                                  : undefined,
                              cursor: "pointer",
                              transition: "all ease 0.3s",
                              ":hover": {
                                filter: !!hasEvent
                                  ? "invert(100%)"
                                  : "brightness(80%)",
                              },
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpenInfoModal(
                                format(day, "yyyy-MM-dd"),
                                treeHours?.[locateHour(j)]?.[mainIndex],
                                sala,
                                !!hasEvent ? "view" : "new",
                                aevent
                              );
                            }}
                          >
                            <Typography
                              sx={{ userSelect: "none" }}
                              variant="caption"
                              color={hasEvent ? getBestColor(acolor) : "#000"}
                            >
                              {index + 1}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Stack>
                  )
                )}
              </Stack>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </Stack>
  );
};

export default Mes;

function locateHour(j) {
  if (j === 0 || j < 7) return 0;
  if (j === 7 || j < 14) return 1;
  if (j === 14 || j < 21) return 2;
  if (j === 21 || j < 28) return 3;
  if (j === 28 || j > 28) return 4;
}
