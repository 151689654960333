import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Cookies from "js-cookie";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SnackAlert from "../../components/SnackAlert";
import GlobalHeader from "../../components/header";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import useSnack from "../../hooks/useSnack";
import Logo from "../../images/logoonlywhite.svg";

function MuiContent() {
  const { loadApi, loadingApi } = useApi();
  const { setLoggedAuth, setUserAuth } = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [remember, setRemember] = useState(true);
  const [mainData, setMainData] = useState({
    username: "",
    password: "",
  });
  const { msgSB, openSB, showSnack, typeSB } = useSnack();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const Login = async (e) => {
    e.preventDefault();
    loadApi("login", false, "post", {
      ...mainData,
      remember: !!remember ? "true" : "false",
    })
      .then((response) => {
        LoginSuccess(response.data);
      })
      .catch((e) => {
        showSnack(e.message);
      });
  };

  async function LoginSuccess(response) {
    if (!!response?.token && !!response?.userInfo) {
      Cookies.set("CCorbitToken", response?.token, {
        expires: !!response?.exp ? new Date(response?.exp * 1000) : undefined,
        secure: true,
        sameSite: "strict",
      });
      setUserAuth({
        data: response?.userInfo,
        exp: !!response.exp ? response.exp : "noremember",
      });
      setLoggedAuth(true);
      navigate("/inventario");
    } else {
      showSnack("Error al conectarse a la API, intente nuevamente.");
    }
  }

  return (
    <>
      <GlobalHeader />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          minHeight: "calc(100vh - 80px)",
          padding: {
            xs: 4,
            sm: 0,
            lg: 4,
            xl: 0,
          },
        }}
      >
        <Stack
          width="100%"
          sx={{
            width: "100%",
            animation: "entryX 0.5s ease forwards",
            maxWidth: {
              sm: "510px",
              xs: "100%",
            },
          }}
          spacing={2}
          component="form"
          onSubmit={Login}
        >
          <Stack direction="row" spacing={3} alignItems="center">
            <Typography variant="h4">Iniciar Sesión</Typography>
            <img alt="Refugios" src={Logo} height={70} />
          </Stack>
          <Divider
            sx={{ height: 2, borderRadius: 2 }}
            variant="fullWidth"
          ></Divider>
          <Stack
            spacing={2}
            sx={{
              backgroundColor: "background.paper",
              padding: "20px",
              boxShadow: "1px 1px 6px #e1e1e1f9",
              borderRadius: 4,
            }}
          >
            <Typography color="GrayText" fontWeight={600}>
              Correo Electrónico
            </Typography>
            <TextField
              onChange={({ target }) =>
                setMainData((adata) => ({ ...adata, username: target.value }))
              }
              value={mainData.username}
              placeholder="ejemplo@mail.com"
              fullWidth
            ></TextField>
            <Typography color="GrayText" fontWeight={600}>
              Contraseña
            </Typography>
            <TextField
              type={passwordVisible ? "text" : "password"}
              variant="outlined"
              name="password"
              fullWidth
              placeholder="************"
              onChange={({ target }) =>
                setMainData((adata) => ({
                  ...adata,
                  password: target.value,
                }))
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControlLabel
              onClick={(e) => {
                e.preventDefault();
                setRemember((adata) => !adata);
              }}
              control={<Checkbox checked={remember} />}
              label="Recordar"
            />
          </Stack>
          <LoadingButton
            loading={!!loadingApi.includes("post__login")}
            fullWidth
            type="submit"
            sx={{
              py: 1.3,
              fontSize: "1.2rem",
            }}
          >
            Iniciar Sesión
          </LoadingButton>
        </Stack>
      </Stack>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
    </>
  );
}

export default MuiContent;
