import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import { useAuth } from "../../hooks/useAuth";
import CloseIcon from "@mui/icons-material/Close";

export default function MultiSelect({
  action,
  options,
  value,
  label,
  tags,
  disabled,
  required,
  fieldname,
  setAllTags,
  showSnack,
}) {
  const { userAuth } = useAuth();
  const isAdmin = ["superadmin", "admin"].includes(userAuth?.role);
  const [openMD, setOpenMD] = useState(false);
  const [tagname, setTagname] = useState("");

  const { loadApi, loadingApi } = useApi();
  function AddTag() {
    loadApi("tags", true, "post", {
      field: fieldname,
      name: tagname,
      color: getRandomColor(),
    })
      .then((response) => {
        showSnack("Etiqueta nueva fue agregada!", "success");
        setAllTags((adata) => [...adata, response.data.item]);
        setOpenMD(false);
        action([...value, response.data.item.id]);
      })
      .catch((e) => showSnack(e.message));
  }

  useEffect(() => {
    setTagname("");
  }, [openMD]);

  return (
    <>
      <Stack direction="row" spacing={1}>
        <Autocomplete
          multiple
          fullWidth
          disabled={disabled}
          value={value.map((val) =>
            options.find((option) => option.id === val)
          )}
          onChange={(event, newValue) =>
            action(newValue.map((option) => option.id))
          }
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              required={!!required}
            />
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Box
                key={option.id}
                sx={{
                  display: "flex",
                  px: 1,
                  gap: 0,
                  alignItems: "center",
                  justifyContent: "left",
                  bgcolor: "rgba(0, 0, 0, 0.08)",
                  borderRadius: "16px",
                  height: "32px",
                  span: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    px: "7px",
                    whiteSpace: "nowrap",
                    fontSize: "0.8125rem",
                    fontWeight: 500,
                  },
                }}
                {...getTagProps({ index })}
              >
                <Box
                  sx={{
                    bgcolor: tags.find((tag) => tag.id === option.id)?.color,
                    width: 15,
                    height: 15,
                    borderRadius: "50%",
                  }}
                />
                <Typography component="span">
                  {tags.find((tag) => tag.id === option.id)?.name}
                </Typography>

                <Box
                  onClick={() => {
                    const newValue = value.filter((val) => val !== option.id);
                    action(newValue);
                  }}
                  sx={{
                    bgcolor: "#0000003b",
                    width: 17,
                    height: 17,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 0.1,
                    cursor: "pointer",
                    transition: "all ease 0.3s",
                    ":hover": {
                      bgcolor: "primary.main",
                      color: "#fff",
                    },
                  }}
                >
                  <CloseIcon sx={{ fontSize: 12 }} />
                </Box>
              </Box>
            ))
          }
        />
        {!!isAdmin && (
          <Button
            onClick={() => setOpenMD(true)}
            sx={{ minWidth: 10, p: 0, px: 0.5 }}
          >
            <AddIcon />
          </Button>
        )}
      </Stack>
      <Modal disableAutoFocus disableEnforceFocus disablePortal open={!!openMD}>
        <Stack
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: 2,
            gap: 1,
            boxShadow: 24,
            borderRadius: 5,
            minWidth: { xs: "100%", sm: 400 },
          }}
        >
          <Typography align="center" variant="h6">
            Agregar nueva etiqueta
          </Typography>
          <TextField
            value={tagname}
            onChange={({ target }) => setTagname(target.value)}
            label="Nombre de la etiqueta"
            fullWidth
          />
          <Stack direction="row" justifyContent="right" spacing={1}>
            <LoadingButton
              disabled={!!loadingApi.length > 0}
              color="info"
              onClick={() => setOpenMD(false)}
            >
              Cerrar
            </LoadingButton>
            <LoadingButton
              loading={!!loadingApi.includes("post__tags")}
              startIcon={<SaveIcon />}
              onClick={AddTag}
            >
              Guardar
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}

function getRandomColor() {
  const randomColors = [
    "#FF5733",
    "#33FF57",
    "#3357FF",
    "#FF33A1",
    "#33FFA1",
    "#A133FF",
    "#FF5733",
    "#57FF33",
    "#5733FF",
    "#FF3357",
    "#33A1FF",
    "#A1FF33",
    "#A13357",
    "#FFA133",
    "#57FFA1",
    "#A157FF",
    "#5733A1",
    "#FF57A1",
    "#A1FF57",
  ];

  const randomIndex = Math.floor(Math.random() * randomColors.length);
  return randomColors[randomIndex];
}
