import GlobalHeader from "../header";
import { Backdrop, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import MenuView from "../MenuView";
import { useState } from "react";
import useApi from "../../hooks/useApi";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import handleInput from "../../utils/handleInput";
import SnackAlert from "../SnackAlert";
import useSnack from "../../hooks/useSnack";
import GenerateFields from "../generateField";
import mainFields from "../../utils/mainFields";
import { useLocation } from 'react-router-dom';

export default function View() {
    const [mainData, setMainData] = useState({});
    const [openBD, setOpenBD] = useState(false);
    const { msgSB, openSB, showSnack, typeSB } = useSnack();
    const { loadApi } = useApi();
    const changeForm = (name) => {
        return handleInput(name, setMainData);
    };
    const location = useLocation();
    const pathFinal = location.pathname.replace("/add", "").replace("/", "");
    const pathBackend = `${mainFields?.[pathFinal]?.backend}`;

    function deleteFiles(name, indexD) {
        if (!!indexD) {
            setMainData((adata) => ({
                ...adata,
                [name]: adata[name].filter((a, index) => (index + 1) !== indexD)
            }));
        } else {
            setMainData((adata) => {
                const aadata = {
                    ...adata,
                    [name]: null
                };
                return aadata
            });
        }
    }

    function submitData() {
        setOpenBD(true);
        loadApi(pathBackend, true, "post", mainData, mainFields?.[pathFinal]?.files ?? false).then((response) => {
            showSnack(response.data.message, "success");
            setOpenBD(false);
            setMainData({});
        }
        ).catch((e) => {
            showSnack(e.message);
            setOpenBD(false);
        });
    }

    return (
        <>
            <Stack>
                <GlobalHeader />
                <MenuView>
                    <Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Button
                                sx={{ py: 0.7, display: "flex", justifyContent: "center", width: "20px!important", px: 0 }}
                                LinkComponent={Link}
                                to={"/" + pathFinal}
                            >
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Typography color="#000000a3" variant="h5">Añadir nuevo</Typography>
                        </Stack>
                        <Divider sx={{ mt: 2 }} />
                        {/* BODY CONTENT */}
                        <Stack spacing={3.5} mt={3.5}>
                            <GenerateFields fields={mainFields?.[pathFinal]?.fields || []}
                                changeForm={changeForm}
                                mainData={mainData}
                                deleteFiles={deleteFiles}
                                pathBackend={pathBackend}
                                mode="add"
                            />
                            <Stack alignItems="end">
                                <Button onClick={submitData} startIcon={<SaveIcon />}> Guardar</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </MenuView>
            </Stack>
            <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}