import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { SketchPicker } from 'react-color'

export default function PickerButton({ color, setColor, disabled }) {
    const [Display, setDisplay] = useState(false);

    const handleClose = () => {
        setDisplay(false)
    };

    function changeColor(acolor) {
        setColor(acolor.hex)
    }


    return (
        <>
            <Button
                disabled={disabled}
                sx={{
                    minWidth: 45,
                    px: 1.2,
                    py: 1
                }} onClick={() =>
                    setDisplay(!Display)}><Box sx={{
                        width: "100%",
                        height: 15,
                        bgcolor: color,
                    }} />
            </Button>
            {Display ? <Box sx={{
                position: 'absolute',
                zIndex: '2',
                "*": {
                    fontFamily: "inherit!important"
                }
            }}>
                <Box sx={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                }} onClick={handleClose} />
                <SketchPicker disableAlpha color={color} onChangeComplete={changeColor} />
            </Box> : null}
        </>
    )
}
