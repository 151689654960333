function dateToInput(fechaOriginal) {
    if (!!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(fechaOriginal)) {
        const dateObject = new Date(fechaOriginal);
        const año = dateObject.getUTCFullYear();
        const mes = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
        const día = dateObject.getUTCDate().toString().padStart(2, "0");

        return `${año}-${mes}-${día}`;
    } else return fechaOriginal;
}

export default dateToInput;