import { Button, Grid, Stack, Typography } from "@mui/material";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  isSameDay,
  parseISO,
  startOfWeek,
} from "date-fns";
import React from "react";

const Semana = ({ currentDate, events, setIsModalOpen }) => {
  function handleOpenModal(mode, aevent, day) {
    if (mode === "new") {
      setIsModalOpen({
        selectedHour: 17,
        selectedDate: day,
        selectedRoom: 1,
      });
    } else {
      setIsModalOpen({ mode: "view", event: aevent });
    }
  }

  const daysOfWeek = eachDayOfInterval({
    start: startOfWeek(currentDate, { weekStartsOn: 1 }),
    end: endOfWeek(currentDate, { weekStartsOn: 1 }),
  });

  return (
    <Stack>
      <Grid container justifyContent="center" alignItems="flex-start">
        {dias.map((dia, index) => (
          <Grid
            item
            key={index}
            xs={1.6}
            sm={1.7}
            sx={{
              textAlign: "center",
              height: "100vh",
              ":hover": { bgcolor: "#bdbdbd" },
              cursor: "pointer",
              transition: "all ease 0.3s",
              borderLeft: index === 0 && "2px solid #bdbdbd",
              borderRight: "2px solid #bdbdbd",
            }}
            onClick={() => handleOpenModal("new", undefined, daysOfWeek[index])}
          >
            <Stack alignItems="center">
              <Typography
                sx={{ pt: 2, display: { xs: "flex", md: "none" } }}
                variant="h6"
                fontWeight={500}
                color="#303030"
              >
                {dia.mobile}
              </Typography>
              <Typography
                sx={{ pt: 2, display: { xs: "none", md: "flex" } }}
                variant="h6"
                fontWeight={500}
                color="#303030"
              >
                {dia.pc}
              </Typography>
              <Typography
                variant="subtitle1"
                fontWeight={400}
                color="#303030"
                mb={2}
              >
                {format(daysOfWeek[index], "dd")}
              </Typography>
            </Stack>

            <Stack spacing={2} alignItems="center" width="100%">
              {events
                .filter((event) =>
                  isSameDay(parseISO(event.date), daysOfWeek[index])
                )
                .map((event, eventIndex) => (
                  <Button
                    key={eventIndex}
                    mt={2}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenModal("view", event);
                    }}
                    sx={{
                      borderRadius: 15,
                      width: "80%",
                    }}
                  >
                    {event.name}
                  </Button>
                ))}
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export default Semana;

const dias = [
  {
    mobile: "L",
    pc: "Lunes",
  },
  {
    mobile: "M",
    pc: "Martes",
  },
  {
    mobile: "M",
    pc: "Miércoles",
  },
  {
    mobile: "J",
    pc: "Jueves",
  },
  {
    mobile: "V",
    pc: "Viernes",
  },
  {
    mobile: "S",
    pc: "Sábado",
  },
  {
    mobile: "D",
    pc: "Domingo",
  },
];
