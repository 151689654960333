import { Grid, Stack, Typography } from "@mui/material";
import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import React from "react";

const Año = ({ currentDate, events, setIsModalOpen }) => {
  const diasmes = ["D", "L", "M", "M", "J", "V", "S"];

  const handleOpenModal = (day) => {
    setIsModalOpen({
      selectedHour: 17,
      selectedDate: day,
      selectedRoom: 1,
    });
  };

  return (
    <Stack>
      <Grid container spacing={0} pt={0}>
        {Array.from({ length: 12 }).map((_, index) => {
          const monthStartDate = new Date(currentDate.getFullYear(), index, 1);
          const firstDayOfMonth = startOfMonth(monthStartDate);
          const lastDayOfMonth = endOfMonth(monthStartDate);
          const firstDayOfWeek = startOfWeek(firstDayOfMonth);
          const lastDayOfWeek = endOfWeek(lastDayOfMonth);
          const daysOfMonth = eachDayOfInterval({
            start: firstDayOfWeek,
            end: lastDayOfWeek,
          });

          return (
            <Grid
              item
              key={index}
              xs={6}
              md={4}
              lg={3}
              py={2}
              container
              justifyContent="center"
              border="1px solid gray"
            >
              <Typography variant="subtitle2" fontWeight={600}>
                {format(monthStartDate, "MMMM")}
              </Typography>
              <Grid container spacing={1}>
                {diasmes.map((dia, index) => (
                  <Grid
                    item
                    xs={1.7}
                    key={index}
                    container
                    justifyContent="center"
                  >
                    <Typography variant="caption" fontWeight={500}>
                      {dia}
                    </Typography>
                  </Grid>
                ))}
                {daysOfMonth.map((day, dayIndex) => {
                  const isEventDay = events.some((event) =>
                    isSameDay(day, parseISO(event.date))
                  );

                  return (
                    <Grid
                      item
                      xs={1.7}
                      key={dayIndex}
                      container
                      justifyContent="center"
                    >
                      <Typography
                        variant="caption"
                        fontWeight={400}
                        sx={{
                          px: 1,
                          py: 0.6,
                          my: 0.3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: isEventDay
                            ? "#002B44"
                            : "transparent",
                          color: isEventDay ? "white" : "black",
                          borderRadius: "4px",
                          cursor: "pointer",
                          ":hover": {},
                        }}
                        onClick={() => handleOpenModal(day)}
                      >
                        {parseInt(format(day, "d"), 10) < 10 && "0"}
                        {format(day, "d")}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default Año;
