import tinycolor from "tinycolor2";

export default function getBestColor(backgroundColor) {
    const bg = tinycolor(backgroundColor).toRgb();
    const white = [255, 255, 255];
    const black = [0, 0, 0];
  
    const contrastWithWhite = getContrast(white, [bg.r, bg.g, bg.b]);
    const contrastWithBlack = getContrast(black, [bg.r, bg.g, bg.b]);
  
    return contrastWithWhite > contrastWithBlack ? "#FFFFFF" : "#000000";
}

function getLuminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
      v = v / 255.0;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }
  
  function getContrast(foreground, background) {
    const lum1 = getLuminance(
      foreground[0],
      foreground[1],
      foreground[2]
    );
    const lum2 = getLuminance(
      background[0],
      background[1],
      background[2]
    );
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }
  