import { Box, Button, Divider, Modal, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PickerButton from "./PickerButton";
import SaveIcon from '@mui/icons-material/Save';

export default function OPmodal({ open, setOpen, save }) {
    const [text, setText] = useState("");
    const [color, setColor] = useState("#fff");

    useEffect(() => {
        if (!!open?.text && !!open?.color) {
            setText(open.text);
            setColor(open.color);
        } else {
            setText("");
            setColor("#fff");
        }
    }, [open])


    return <Modal open={!!open} onClose={() => setOpen(null)} disableAutoFocus disableEnforceFocus>
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 3,
                borderRadius: 5,
                minWidth: 300,
                width: { xs: 300, sm: 500 },
            }}
        >
            <Stack spacing={1}>
                <Typography variant="h6">{open?.mode === "edit" ? "Editar" : "Agregar"} etiqueta ({open?.label})</Typography>
                <Divider />
                <Typography>Nombre de la etiqueta</Typography>
                <TextField value={text} onChange={({ target }) => setText(target.value)} />
                <Stack direction="row" alignItems="center" spacing={1.5} pt={1}>
                    <Typography>Color: </Typography>
                    <PickerButton color={color} setColor={setColor} />
                </Stack>
                <Stack direction="row" justifyContent="right" spacing={1}>
                    <Button color="error" onClick={() => setOpen(null)}>Cancelar</Button>
                    <Button startIcon={<SaveIcon />} onClick={() => save(open?.field, text, color, open?.mode, open?.id)}>Guardar</Button>
                </Stack>

            </Stack>
        </Box>
    </Modal>
}