// Pages
import Login from "./pages/login";
import Logout from "./pages/login/logout";
import Calendario from "./pages/calendario";
import ActionAdd from "./components/actions/add"
import ActionEdit from "./components/actions/edit"
import ActionView from "./components/actions/view"
import Usuarios from "./pages/usuarios"
import Centros from "./pages/centros"
import Tags from "./pages/tags"

const routes = [
  {
    route: "/login",
    role: "auth",
    component: <Login />,
  },
  {
    route: "/logout",
    component: <Logout />,
  },
  //--------------DASHBOARD ROUTES----------------
  {
    route: "/etiquetas",
    role: ["superadmin", "admin"],
    component: <Tags />,
  },
  {
    route: "/calendario",
    // role: "logged",
    component: <Calendario />,
  },
  {
    route: "/centros",
    role: ["superadmin"],
    component: <Centros />,
  },
  {
    route: "/centros/add",
    role: ["superadmin"],
    component: <ActionAdd />,
  },
  {
    route: "/centros/edit/:elementId",
    role: ["superadmin"],
    component: <ActionEdit />,
  },
  {
    route: "/centros/view/:elementId",
    role: ["superadmin"],
    component: <ActionView />,
  },
  //--------------SUPERADMIN ROUTES----------------
  {
    route: "/usuarios",
    role: "superadmin",
    component: <Usuarios />,
  },
  {
    route: "/usuarios/add",
    role: "superadmin",
    component: <ActionAdd />,
  },
  {
    route: "/usuarios/edit/:elementId",
    role: "superadmin",
    component: <ActionEdit />,
  },
  {
    route: "/usuarios/view/:elementId",
    role: "superadmin",
    component: <ActionView />,
  },
];

export default routes;
