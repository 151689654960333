import {
    Autocomplete,
    Button,
    Divider,
    InputAdornment,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ImageBox from "./ImageBox";
import getPreview from "../utils/getPreview";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import useApi from "../hooks/useApi";
import dateToInput from "../utils/dateToInput";
import formatPrice from "../utils/formatPrice";

export default function GenerateFields({
    fields = [],
    disabled,
    changeForm = () => { },
    mainData,
    deleteFiles = undefined,
    mode,
    pathBackend,
}) {
    const componentes = [];
    var i = 0;

    const { loadApi } = useApi();
    const [selectOptions, setSelectOptions] = useState({});
    const [loadingSO, setLoadingSO] = useState(false);

    useEffect(() => {
        setLoadingSO(true);
        loadApi(`${pathBackend}/select_options`, true, "get")
            .then((response) => {
                setSelectOptions(response.data);
                setLoadingSO(false);
            })
            .catch((e) => {
                // setLoadingSO(false);
            });
    }, []);

    for (let field of fields) {
        if (field.type === "custom") {
            componentes.push(
                <field.component disabled={disabled} changeForm={changeForm} mainData={mainData} mode={mode} deleteFiles={deleteFiles} pathBackend={pathBackend} key={i} />
            );
        }


        if (["text", "number", "float", "password", "email", "date"].includes(field.type)) {
            if (field.type === "date") mainData[field.name] = dateToInput(mainData?.[field.name]);
            componentes.push(
                <Stack key={i} spacing={3.5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography sx={textSx}>{field.label}</Typography>
                        <TextField
                            placeholder={
                                !!field.placeholderview
                                    ? field.placeholderview.includes(mode)
                                        ? field.placeholder
                                        : undefined
                                    : field.placeholder || undefined
                            }
                            type={["number", "float"].includes(field.type) ? "text" : field.type}
                            disabled={disabled || field.disabled}
                            InputProps={{
                                startAdornment: !!field.startIcon ? (
                                    <InputAdornment position="start">
                                        <field.startIcon />
                                    </InputAdornment>
                                ) : undefined,
                                endAdornment: !!field.endIcon ? (
                                    <InputAdornment position="start">
                                        <field.endIcon />
                                    </InputAdornment>
                                ) : undefined,
                            }}
                            sx={inputSx}

                            value={mainData?.[field.name] || ""}
                            defaultValue={field.defaultValue || undefined}
                            onChange={(e) => {
                                if (field.type === "number") e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                if (field.type === "float") e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                changeForm(field.name)(e);
                                if (!!field.customChange) field.customChange(mainData, field.name, e, changeForm);
                            }}
                            fullWidth={mode === "view"}
                            multiline={mode === "view"}
                        />
                    </Stack>
                    <Divider />
                </Stack>
            );
        }

        if (field.type === "file") {
            componentes.push(
                <Stack key={i} spacing={3.5}>
                    <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
                        <Typography sx={textSx}>{field.label}</Typography>
                        {mode !== "view" && (
                            <Button
                                disabled={disabled}
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                            >
                                Elegir archivo
                                <VisuallyHiddenInput
                                    accept=".jpg,.jpeg,.png,.webp,.gif,.svg"
                                    onChange={changeForm(
                                        mode === "edit" ? field.name + "_edit" : field.name
                                    )}
                                    type="file"
                                />
                            </Button>
                        )}
                        <Typography color="GrayText" noWrap sx={text2Sx}>
                            {mainData?.[field.name + "_edit"]?.name ??
                                mainData?.[field.name + "_edit"]?.name ??
                                "No has seleccionado ningún archivo."}
                        </Typography>
                    </Stack>
                    {(!!mainData?.[field.name] ||
                        !!mainData?.[field.name + "_edit"]?.url) && (
                            <Stack sx={text3Sx} direction="row" gap={2}>
                                <ImageBox
                                    Delete={
                                        deleteFiles === undefined
                                            ? undefined
                                            : () => deleteFiles(field.name)
                                    }
                                    src={
                                        getPreview(
                                            mainData?.[
                                            mode === "edit" ? field.name + "_edit" : field.name
                                            ]
                                        ) ?? mainData?.[field.name]?.url
                                    }
                                />
                            </Stack>
                        )}
                    <Divider />
                </Stack>
            );
        }

        if (field.type === "autocomplete") {

            componentes.push(
                <Stack key={i} spacing={3.5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography sx={textSx}>{field.label}</Typography>
                        <Autocomplete
                            disabled={disabled}
                            value={selectOptions?.[field.name]?.find((aitem) => aitem?.value === mainData?.[field.name])?.label || ""}
                            options={selectOptions[field.name] || []}
                            onChange={changeForm(field.name)}
                            renderInput={(params) => <TextField sx={inputSx} {...params} variant="outlined" />}
                        />
                    </Stack>
                    <Divider />
                </Stack>
            );
        }

        if (field.type === "select" || field.type === "select_manual") {
            var selectFields = [];

            if (field.type === "select") {
                selectFields = selectOptions?.[field.name] || [];
            }

            if (!!field?.defaultValue) {
                if (!mainData?.[field.name]) {
                    mainData[field.name] = field.defaultValue;
                    mainData[field.name + "Id"] = field.defaultValue;
                }
            }

            componentes.push(
                <Stack key={i} spacing={3.5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography sx={textSx}>{field.label}</Typography>
                        <Select
                            sx={inputSx}
                            onChange={changeForm(field.name)}
                            value={mainData?.[field.name] || mainData?.[field.name + "Id"] || field?.defaultValue || "default__00"}
                            disabled={disabled}
                        >
                            <MenuItem disabled value="default__00">
                                {field.type === "select" ? !loadingSO ? "Selecciona uno..." : "Cargando las opciones..." : "Selecciona uno..."}
                            </MenuItem>
                            {field.type === "select" &&
                                selectFields.map((aitem, i) => (
                                    <MenuItem
                                        key={i}
                                        value={aitem.value}
                                        disabled={!aitem?.value}
                                    >
                                        {aitem.label}
                                    </MenuItem>
                                ))}
                            {field.type === "select_manual" &&
                                field.select_fields.map((aitem, i) => (
                                    <MenuItem key={i} value={aitem.value}>
                                        {aitem.label}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Stack>
                    <Divider />
                </Stack>
            );
        }

        if (field.type === "fileMultiple") {
            componentes.push(
                <Stack key={i} spacing={3.5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography sx={textSx}>{field.label}</Typography>
                        {mode !== "view" ? (
                            <Button
                                disabled={disabled}
                                component="label"
                                variant="contained"
                                startIcon={<CloudUploadIcon />}
                            >
                                Elegir archivos
                                <VisuallyHiddenInput
                                    multiple
                                    onChange={changeForm(
                                        mode === "edit" ? field.name + "_edit" : field.name
                                    )}
                                    type="file"
                                />
                            </Button>
                        ) : (
                            <Typography color="GrayText">
                                Hay{" "}
                                {!!mainData?.[field.name]?.length
                                    ? mainData?.[field.name]?.length
                                    : 0}{" "}
                                archivos subidos.
                            </Typography>
                        )}
                    </Stack>
                    <Stack
                        sx={text3Sx}
                        direction="row"
                        gap={2}
                        alignItems="center"
                        flexWrap="wrap"
                    >
                        {(!!mainData?.[field.name + "_edit"] &&
                            mainData?.[field.name + "_edit"].length > 0
                            ? mainData?.[field.name + "_edit"]
                            : []
                        ).map((aitem, i) => (
                            <ImageBox
                                Delete={
                                    deleteFiles === undefined
                                        ? undefined
                                        : () => deleteFiles(field.name + "_edit", i + 1)
                                }
                                key={i}
                                src={getPreview(aitem) ?? aitem?.url}
                            />
                        ))}
                        {(!!mainData?.[field.name] && mainData?.[field.name].length > 0
                            ? mainData?.[field.name]
                            : []
                        ).map((aitem, i) => (
                            <ImageBox
                                Delete={
                                    deleteFiles === undefined
                                        ? undefined
                                        : () => deleteFiles(field.name, i + 1)
                                }
                                key={i}
                                src={getPreview(aitem) ?? aitem?.url}
                            />
                        ))}
                    </Stack>
                    <Divider />
                </Stack>
            );
        }

        if (field.type === "rows") {
            componentes.push(
                <Stack key={i} spacing={3.5}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Typography sx={textSx}>{field.label}</Typography>
                        <Stack component="ul" gap={1}>
                            {(mainData?.[field.name] || []).map(((aitem, i) => <li key={i}>{aitem.name} - {aitem.quantity} Unidades - Precio Unidad: {formatPrice(aitem.price)} - Total: {formatPrice(aitem.price * aitem.quantity)}</li>))}
                            {(mainData?.[field.name] || []).length < 1 && <Typography color="GrayText" >No hay ninguno.</Typography>}
                        </Stack>
                    </Stack>
                    <Divider />
                </Stack>
            );
        }

        i++;
    }

    return <>{componentes}</>;
}

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

const inputSx = {
    width: {
        xs: "100%",
        sm: 320,
        md: 500,
    },
    input: {
        height: "0.6em",
        width: {
            xs: "100%",
            sm: 320,
            md: 500,
        },
    },
};

const textSx = {
    textAlign: {
        xs: "left",
        md: "right",
    },
    width: {
        xs: "30%",
        sm: 120,
        md: 200,
        lg: 250,
    },
};

const text2Sx = {
    pl: {
        xs: 0,
        sm: "136px",
        md: 0,
    },
};

const text3Sx = {
    pl: {
        xs: 0,
        sm: "136px",
        md: "266px",
    },
};
