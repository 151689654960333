import { DataGrid, esES } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const DataTable = ({
  rows,
  searchTerm,
  searchField,
  searchOperator,
  Delete,
  Edit,
  View,
  Columns,
  noActions,
  disableEdit,
  disableDelete,
  disableShow,
}) => {
  const theme = useTheme();
  const { breakpoints } = theme;
  const isLg = useMediaQuery(breakpoints.only("lg"));
  const isMobile = useMediaQuery(breakpoints.down("lg"));

  const CustomCell = (props) => (
    <Stack
      id={props.row.id}
      key={props.row.id}
      direction="row"
      sx={{ maxWidth: "100%" }}
      spacing={1}
    >
      {!disableShow && <Button
        onClick={() => View(props.row.id)}
        sx={{
          px: 1.3,
          py: 1,
          width: "fit-content",
          minWidth: 0,
          lineHeight: 1,
          bgcolor: "#ededed",
          ":hover": {
            bgcolor: "#cccccc",
          },
        }}
      >
        <RemoveRedEyeIcon sx={{ color: "#535353f0", fontSize: "1.2rem" }} />
      </Button>
      }
      {!disableEdit && <Button
        onClick={() => Edit(props.row.id)}
        color="primary"
        sx={{
          px: 1.3,
          py: 1,
          width: "fit-content",
          minWidth: 0,
          lineHeight: 1,
        }}
      >
        <EditIcon sx={{ fontSize: "1.2rem" }} />
      </Button>
      }
      {!disableDelete && <Button
        onClick={() => Delete(props.row.id)}
        color="error"
        sx={{
          px: 1.3,
          py: 1,
          width: "fit-content",
          minWidth: 0,
          lineHeight: 1,
        }}
      >
        <DeleteIcon sx={{ fontSize: "1.2rem" }} />
      </Button>
      }

    </Stack>
  );

  const columns = Columns.map((aitem) => ({
    field: aitem.name,
    headerName: aitem.label,
    flex: isMobile ? undefined : aitem.flex,
    width: !isMobile ? undefined : 200,
    renderCell: !!aitem.render
      ? (params) => <div dangerouslySetInnerHTML={{ __html: params.value }} />
      : undefined,
  }));

  if (!noActions)
    columns.push({
      field: "actions",
      headerName: "Acciones",
      disableColumnMenu: true,
      filterable: false,
      sortable: false,
      flex: isMobile ? undefined : 13,
      width: !isMobile ? undefined : 150,
      renderCell: (params) => <CustomCell {...params} />,
    });

  //---------- BUSQUEDA ------------
  const [filterModel, setFilterModel] = useState({ items: [] });
  useEffect(() => {
    const newFilterModel = {
      items: [
        {
          field: searchField ?? "name",
          operator: searchOperator ?? "contains",
          value: searchTerm || "",
        },
      ],
    };
    setFilterModel(newFilterModel);
  }, [searchTerm]);

  return (
    <DataGrid
      rows={rows}
      localeText={{
        ...esES.components.MuiDataGrid.defaultProps.localeText,
        noRowsLabel: "No se encontraron elementos de esta tabla en el sistema.",
      }}
      columns={columns}
      density="comfortable"
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: isLg ? 6 : 12,
          },
        },
      }}
      pageSizeOptions={[isLg ? 6 : 12]}
      disableColumnSelector
      disableRowSelectionOnClick
      disableDensitySelector
      filterModel={filterModel}
      onFilterModelChange={(model) => setFilterModel(model)}
      disableVirtualization
      rowHeight={37}
      autoHeight
      sx={{
        borderColor: "transparent",
        "& .Mui-disabled": {
          svg: {
            color: "#82828252!important",
          },
        },
        "& .MuiDataGrid-cell:focus": {
          outline: "none!important",
        },
      }}
    />
  );
};

export default DataTable;
