import { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  Box,
  Avatar,
  ListItemAvatar,
  Stack,
  Container,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import { Link } from "react-router-dom";
import headerOptions from "../utils/headerOptions";
import { useAuth } from "../hooks/useAuth";
import Logo from "../images/logowhite.svg";

function GlobalHeader() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { loggedAuth, userAuth } = useAuth();
  const [options, setOptions] = useState(
    loggedAuth ? headerOptions.header : headerOptions.default
  );

  useEffect(() => {
    setOptions(!!loggedAuth ? headerOptions.header : headerOptions.default);
  }, [loggedAuth]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  return (
    <>
      <AppBar id="GlobalHeader" position="static">
        <Container
          sx={{
            py: 1,
          }}
        >
          <Toolbar>
            {!!loggedAuth && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{
                  color: "#fff",
                  display: {
                    xs: "inherit",
                    lg: "none",
                  },
                }}
                onClick={toggleDrawer(true)}
              >
                <Menu />
              </IconButton>
            )}
            <Stack flexGrow={1} direction="row" alignItems="center" spacing={1}>
              <Box
                component={Link}
                to="/"
                sx={{ pr: 1, borderRight: "2px solid white" }}
              >
                <img src={Logo} width={200} height="100%" />
              </Box>
              <Typography variant="h6" color="white" noWrap>
                {userAuth.name}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              sx={{
                display: {
                  xs: "none",
                  lg: "inherit",
                },
              }}
              spacing={3}
            >
              {options.map((row, key) => {
                return (
                  <Box
                    key={key}
                    component={Link}
                    to={row.route}
                    display="flex"
                    alignItems="center"
                    gap="3px"
                    sx={{
                      color: "#fff",
                      cursor:
                        window.location.pathname === row.route
                          ? "default"
                          : "pointer",
                      borderBottom: "2px solid",
                      transition: "border-color 0.2s ease-in-out",
                      pb: 1,
                      borderColor:
                        window.location.pathname === row.route
                          ? "#fff"
                          : "secondary.main",
                      ":hover": {
                        borderColor: "white",
                      },
                    }}
                  >
                    <row.icon />
                    <Typography color="white">{row.title}</Typography>
                  </Box>
                );
              })}
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <Box bgcolor="black" px={2} height="100vh" overflow="hidden">
            <List sx={{ height: "100%", p: 0, pt: 2 }}>
              <Stack justifyContent="space-between" sx={{ height: "100%" }}>
                <Box>
                  <ListItem sx={{ my: 1 }}>
                    <ListItemAvatar>
                      <Avatar
                        src={Logo}
                        sx={{
                          width: "180px",
                          height: "auto",
                          borderRadius: 0,
                        }}
                      />
                    </ListItemAvatar>
                  </ListItem>
                  {options.map((item, i) => (
                    <Link key={i} to={item.route}>
                      <ListItem>
                        <Stack
                          spacing={1}
                          direction="row"
                          sx={{
                            borderBottom: "3px solid",
                            borderColor:
                              window.location.pathname === item.route
                                ? "#fff"
                                : "secondary.main",
                            width: "100%",
                            py: 1,
                            svg: {
                              color: "#fff!important",
                            },
                          }}
                        >
                          <item.icon />
                          <Typography color="white">{item.title}</Typography>
                        </Stack>
                      </ListItem>
                    </Link>
                  ))}
                </Box>
              </Stack>
            </List>
          </Box>
        </div>
      </Drawer>
    </>
  );
}

export default GlobalHeader;
