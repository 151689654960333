import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { pathToRegexp } from "path-to-regexp";
import useApi from "./useApi";
import main_routes from "../main_routes";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [loggedAuth, setLoggedAuth] = useState(Boolean(!!Cookies.get("CCorbitToken") && Cookies.get("CCorbitUserInfo")));
  var userp = {};
  var children2 = children;
  try {
    userp = JSON.parse(Cookies.get("CCorbitUserInfo"));
  } catch (e) { }
  const [userAuth, setUserState] = useState(userp);
  const { loadApi } = useApi();

  //Hacer petición a update user cada vez que se ejecuta una página
  useEffect(() => {
    updateUserAuth();
    updateTokenAuth();
  }, []);

  //Función para actualizar user desde cualquier vista
  const setUserAuth = useCallback((newUserAuth) => {
    if (
      !!newUserAuth?.data?.username &&
      !!newUserAuth?.data?.name &&
      !!newUserAuth?.data?.role &&
      !!newUserAuth?.exp
    ) {
      setUserState(newUserAuth.data);
      try {
        Cookies.set(
          "CCorbitUserInfo",
          JSON.stringify(newUserAuth.data),
          {
            expires: newUserAuth.exp === "noremember" ? undefined : new Date(newUserAuth.exp * 1000),
            secure: true,
            sameSite: "strict",
          }
        );
      } catch (e) {
        console.error(e);
      }
    }
  }, []);

  // Realizar chequeo de roles, sino existe lo saca del sistema y vuelve a login
  const findMatchingRoute = (routes, currentPath) => {
    for (const route of routes) {
      const regex = pathToRegexp(route.route);
      if (regex.test(currentPath)) {
        return route;
      }
    }
    return null;
  };

  const element = findMatchingRoute(main_routes, window.location.pathname);

  if (!!element?.role) {
    if (element.role === "auth") {
      if (!!loggedAuth) {
        children2 = false;
        window.location.replace("/");
      }
    } else {
      if (!loggedAuth) {
        logout();
      }

      if (typeof element.role === "string") {
        if (element.role !== userAuth?.role) {
          toHome();
        }
      } else if (typeof element.role === "object") {
        if (!element.role.includes(userAuth?.role)) {
          toHome();
        }
      }
    }
  }

  //Actualizar Token antes de que expire
  function updateTokenAuth() {
    if (loggedAuth) {
      loadApi("token/verify", true, "get")
        .then((response) => {
          if (response.data.message !== "ok") logout();
          if (!!response.data.token && !!response.data.exp) {
            Cookies.set('CCorbitUserInfo', JSON.stringify(JSON.parse(Cookies.get("CCorbitUserInfo"))), {
              expires: new Date(response.data.exp * 1000),
              secure: true,
              sameSite: 'strict'
            });

            Cookies.set('CCorbitToken', response.data.token, {
              expires: new Date(response.data.exp * 1000),
              secure: true,
              sameSite: 'strict'
            });
          }
        })
        .catch((e) => logout());
    }
  }

  //actualizar cookies desde el backend (solo si esta logueado)
  function updateUserAuth() {
    if (loggedAuth) {
      loadApi("userInfo", true, "get")
        .then((response) => {
          if (response?.data) setUserAuth(response?.data);
        })
        .catch((e) => { });
    }
  }

  function toHome() {
    children2 = false;
    window.location.replace("/");
  }

  function logout() {
    children2 = false;
    logoutAuth();
  }

  function logoutAuth() {
    const allCookies = Cookies.get();
    for (let cookie in allCookies) {
      Cookies.remove(cookie);
    }
    window.location.replace("/login");
  }

  return (
    <AuthContext.Provider value={{ loggedAuth, setLoggedAuth, updateUserAuth, userAuth, setUserAuth, logoutAuth }}>
      {children2}
    </AuthContext.Provider>
  );
};
