import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import SeventeenMpRoundedIcon from "@mui/icons-material/EventRounded";
import LibraryAddRoundedIcon from "@mui/icons-material/LibraryAddRounded";
import SaveIcon from "@mui/icons-material/Save";
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { toZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import handleInput from "../../utils/handleInput";
import MultiSelect from "./MultiSelect";

function EventModal({
  open,
  onClose,
  rooms,
  tags,
  setOpenBD,
  showSnack,
  setEvents,
  selectCampus,
  setAllTags,
}) {
  const { loadApi } = useApi();
  const [editer, setEditer] = useState(false);
  const servicio = (tags || []).filter((aaitem) => aaitem.field === "services");
  const estados = (tags || []).filter((aaitem) => aaitem.field === "status");
  const profesores = (tags || []).filter(
    (aaitem) => aaitem.field === "professor"
  );
  const tipoevento1 = (tags || []).filter(
    (aaitem) => aaitem.field === "eventType"
  );

  const initData = {
    campus_id: selectCampus,
    name: "",
    description: "",
    date: formatDate(open?.selectedDate),
    time: open?.selectedHour || horas[0],
    duration: 1,
    eventType: [],
    room: open?.selectedRoom || 1,
    nameClient: "",
    cellphone: "",
    email: "",
    professor: [],
    price: "",
    paymentStatus: "",
    status: [],
    services: [],
    isPending: false,
    isLoop: false,
  };
  const [formData, setFormData] = useState(
    open?.mode === "view" ? open.event : initData
  );

  useEffect(() => {
    setEditer(false);
    setFormData(open?.mode === "view" ? open.event : initData);
  }, [open]);

  //Guardar Evento
  const handleSave = () => {
    if (open?.mode === "view" && !editer) {
      setEditer(true);
      return;
    }
    setOpenBD(true);

    loadApi("events", true, "post", formData)
      .then((response) => {
        showSnack(response.data.message, "success");
        setEvents((aaevents) => [...aaevents, response.data.item]);
        onClose();
      })
      .catch((e) => showSnack(e.message))
      .finally(() => setOpenBD(!true));
  };

  function deleteEvent() {
    const confirmer = window.confirm("¿Deseas eliminar este evento?");
    if (!confirmer) return;
    setOpenBD(true);

    loadApi("events/" + formData.id, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        setEvents((aaevents) =>
          aaevents.filter((aaevent) => aaevent.id !== formData.id)
        );
        onClose();
      })
      .catch((e) => showSnack(e.message))
      .finally(() => setOpenBD(!true));
  }

  function EditEvent() {
    setOpenBD(true);
    loadApi("events/" + formData.id, true, "put", formData)
      .then((response) => {
        showSnack(response.data.message, "success");
        setEvents((aevents) => {
          const newEevent = [...aevents];
          newEevent[aevents.findIndex((aaitem) => aaitem.id === formData.id)] =
            response.data.item;
          return newEevent;
        });
        setEditer(false);
        onClose();
      })
      .catch((e) => showSnack(e.message))
      .finally(() => setOpenBD(!true));
  }

  function changeForm(name) {
    return handleInput(name, setFormData);
  }

  const changeMultiselect = (formField) => (event) => {
    const value = event;
    setFormData((aobject) => ({
      ...aobject,
      [formField]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      disablePortal
      open={!!open}
      onClose={onClose}
    >
      <Stack
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          padding: 3,
          pb: 2,
          boxShadow: 24,
          borderRadius: 5,
          minWidth: 300,
          width: { xs: 300, sm: 500, md: 800, lg: 1100 },
        }}
      >
        <Stack spacing={0.5} mb={3.2}>
          <Typography
            variant="h5"
            component="h2"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {open?.mode === "view" ? (
              !editer ? (
                <SeventeenMpRoundedIcon />
              ) : (
                <EditNoteRoundedIcon />
              )
            ) : (
              <LibraryAddRoundedIcon />
            )}
            {open?.mode === "view"
              ? !!editer
                ? "Editar este"
                : "Ver"
              : "Crear nuevo"}{" "}
            evento
          </Typography>
          <Divider sx={{ py: 0.3 }} />
        </Stack>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              type="date"
              label="Fecha del Evento"
              value={formData.date}
              onChange={changeForm("date")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="hourlabel">
                Hora del evento <b style={{ color: "red" }}>*</b>
              </InputLabel>
              <Select
                required
                disabled={open?.mode === "view" && !editer}
                label="Hora del evento"
                labelId="hourlabel"
                value={formData.time}
                onChange={changeForm("time")}
                fullWidth
              >
                {horas.map((hora) => (
                  <MenuItem key={hora} value={hora}>
                    {hora}:30
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <FormControl fullWidth>
              <InputLabel id="room-input">
                Número de sala <b style={{ color: "red" }}>*</b>
              </InputLabel>
              <Select
                required
                disabled={open?.mode === "view" && !editer}
                labelId="room-input"
                label="Número de sala"
                value={formData.room}
                onChange={changeForm("room")}
                fullWidth
                margin="normal"
              >
                {Array.from({ length: rooms }).map((_, iroom) => (
                  <MenuItem key={iroom} value={iroom + 1}>
                    {iroom + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              label="Nombre del Evento"
              value={formData.name}
              onChange={changeForm("name")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              label="Descripción"
              value={formData.description}
              onChange={changeForm("description")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <MultiSelect
              required
              disabled={open?.mode === "view" && !editer}
              value={formData.eventType}
              label="Tipo de evento"
              options={tipoevento1}
              action={changeMultiselect("eventType")}
              tags={tags}
              fieldname="eventType"
              setAllTags={setAllTags}
              showSnack={showSnack}
            />
          </Grid>

          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              label="Nombre cliente"
              value={formData.nameClient}
              onChange={changeForm("nameClient")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              label="Teléfono"
              value={formData.cellphone}
              onChange={changeForm("cellphone")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              label="Correo electrónico"
              value={formData.email}
              onChange={changeForm("email")}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <MultiSelect
              disabled={open?.mode === "view" && !editer}
              value={formData.professor}
              label="Profesor"
              options={profesores}
              action={changeMultiselect("professor")}
              tags={tags}
              fieldname="professor"
              setAllTags={setAllTags}
              showSnack={showSnack}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              fullWidth
              disabled={open?.mode === "view" && !editer}
              value={formData.price}
              onChange={(e) => {
                setFormData((aobject) => ({
                  ...aobject,
                  price: e.target.value.replace(/[^0-9.]/g, ""),
                }));
              }}
              label="Precio"
              type="text"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              fullWidth
              disabled={open?.mode === "view" && !editer}
              value={formData.paymentStatus}
              onChange={(e) => {
                setFormData((aobject) => ({
                  ...aobject,
                  paymentStatus: e.target.value.replace(/[^0-9.]/g, ""),
                }));
              }}
              label="Estado de pago"
              type="text"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
            />
          </Grid>

          <Grid item xs={6} md={6} lg={4}>
            <MultiSelect
              disabled={open?.mode === "view" && !editer}
              value={formData.status}
              label="Estado"
              options={estados}
              action={changeMultiselect("status")}
              tags={tags}
              fieldname="status"
              setAllTags={setAllTags}
              showSnack={showSnack}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <MultiSelect
              disabled={open?.mode === "view" && !editer}
              value={formData.services}
              label="Servicios"
              options={servicio}
              action={changeMultiselect("services")}
              tags={tags}
              fieldname="services"
              setAllTags={setAllTags}
              showSnack={showSnack}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={4}>
            <TextField
              disabled={open?.mode === "view" && !editer}
              label="Notas"
              multiline
              fullWidth
              rows={2}
              value={formData.notes}
              onChange={changeForm("notes")}
            />
          </Grid>
        </Grid>
        <Stack
          mt={1}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            sx={{
              h6: {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            <FormControlLabel
              sx={{
                span: { userSelect: "none" },
                height: 30,
                pr: 2,
                borderRight: "1px solid #8a8a8a99",
              }}
              control={
                <Checkbox
                  disabled={open?.mode === "view" && !editer}
                  checked={formData.isLoop}
                  onChange={changeForm("isLoop")}
                />
              }
              label="Evento repetible"
            />
            <FormControlLabel
              sx={{ span: { userSelect: "none" } }}
              control={
                <Checkbox
                  disabled={open?.mode === "view" && !editer}
                  checked={formData.isPending}
                  onChange={changeForm("isPending")}
                />
              }
              label="Pendiente"
            />
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Button
              color="info"
              variant="contained"
              onClick={onClose}
              sx={{ mt: 2, height: "fit-content" }}
            >
              Cerrar
            </Button>
            {open?.mode === "view" && (
              <Button
                color="error"
                variant="contained"
                startIcon={<DeleteRoundedIcon />}
                onClick={deleteEvent}
                sx={{ mt: 2, height: "fit-content" }}
              >
                Eliminar
              </Button>
            )}
            <Button
              startIcon={
                open?.mode === "view" && !editer ? (
                  <EditNoteRoundedIcon />
                ) : (
                  <SaveIcon />
                )
              }
              variant="contained"
              onClick={!!editer ? EditEvent : handleSave}
              sx={{ mt: 2, height: "fit-content" }}
              disabled={!isCompleteForm(formData)}
            >
              {open?.mode === "view" && !editer ? "Editar" : "Guardar"}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
}

export default EventModal;

function isCompleteForm(formData) {
  if (
    formData.date &&
    formData.time &&
    formData.eventType.length > 0 &&
    formData.room
  ) {
    return true;
  } else {
    return false;
  }
}

const horas = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
const estadopagado = ["Pagado", "Pendiente"];

function formatDate(date) {
  // Definir la zona horaria de Madrid
  const timeZone = "Europe/Madrid";

  // Convertir la fecha de entrada a la zona horaria de Madrid
  const d = toZonedTime(new Date(date), timeZone);

  // Obtener los componentes de la fecha en la zona horaria de Madrid
  const year = d.getFullYear();
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);

  return [year, month, day].join("-");
}
