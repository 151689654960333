import GlobalHeader from "../header";
import { Backdrop, Button, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import MenuView from "../MenuView";
import { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import handleInput from "../../utils/handleInput";
import SnackAlert from "../SnackAlert";
import useSnack from "../../hooks/useSnack";
import { useParams } from 'react-router-dom';
import GenerateFields from "../generateField";
import { useLocation } from 'react-router-dom';
import mainFields from "../../utils/mainFields";

export default function View() {
    const [mainData, setMainData] = useState({});
    const [openBD, setOpenBD] = useState(false);
    const { msgSB, openSB, showSnack, typeSB } = useSnack();
    const { loadApi, loadedApi } = useApi();
    const { elementId } = useParams();
    const changeForm = (name) => {
        return handleInput(name, setMainData);
    };
    const location = useLocation();
    const match = location.pathname.match(/^\/([^\/]+)\//);
    const pathFinal = match ? match[1] : '';
    const pathBackend = `${mainFields?.[pathFinal]?.backend}/${elementId}`;

    function submitData() {
        setOpenBD(true);
        loadApi(pathBackend, true, "put", mainData, mainFields?.[pathFinal]?.files ?? false).then((response) => {
            showSnack(response.data.message, "success");
            setOpenBD(false);
        }
        ).catch((e) => {
            showSnack(e.message);
            setOpenBD(false);
        });
    }

    function deleteFiles(name, indexD) {
        if (!!indexD) {
            setMainData((adata) => ({
                ...adata,
                [name]: adata[name].filter((a, index) => (index + 1) !== indexD)
            }));
        } else {
            setMainData((adata) => {
                const aadata = {
                    ...adata,
                    [name]: null
                };
                delete aadata[name + "_edit"];
                return aadata
            });
        }
    }

    //Cargar datos
    useEffect(() => {
        setOpenBD(true);
        loadApi(pathBackend, true, "get").then((response) => {
            setMainData(response.data);
            setOpenBD(false);
        }
        ).catch((e) => {
            showSnack(e.message, undefined, 0);
            setOpenBD(false);
        });
    }, []);

    return (
        <>
            <Stack>
                <GlobalHeader />
                <MenuView>
                    <Stack>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                        >
                            <Button
                                sx={{ py: 0.7, display: "flex", justifyContent: "center", width: "20px!important", px: 0 }}
                                LinkComponent={Link}
                                to={"/" + pathFinal}
                            >
                                <KeyboardArrowLeftIcon />
                            </Button>
                            <Typography color="#000000a3" variant="h5">Editar {pathFinal}</Typography>
                        </Stack>
                        <Divider sx={{ mt: 2 }} />
                        {/* BODY CONTENT */}
                        <Stack spacing={3.5} mt={3.5}>
                            <GenerateFields fields={mainFields?.[pathFinal]?.fields || []}
                                changeForm={changeForm}
                                mainData={mainData}
                                deleteFiles={deleteFiles}
                                disabled={!loadedApi.includes("get__" + pathBackend)}
                                mode="edit"
                                pathBackend={mainFields?.[pathFinal]?.backend}
                            />
                            <Stack alignItems="end">
                                <Button disabled={!loadedApi.includes("get__" + pathBackend)} onClick={submitData} startIcon={<SaveIcon />}> Guardar</Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </MenuView>
            </Stack>
            <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBD}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}